.action-label {
    font-size: 20px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
    color: black;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
    border-radius: 12px;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 1) !important;
}

.help {
    margin: 10px 0;
    color: gray;
}

.fontStyle,
.fontStyle input {
    font-weight: 600 !important;
    font-size: large !important;
}

.dropdown,
.dropdown input {
    padding: 0 !important;
}

.padding input {
    padding: 8.5px 13px !important;
}